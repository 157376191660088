<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <polygon
      style="fill:#F2F2F2;"
      points="68.437,430.679 68.437,144.355 443.564,144.355 443.564,512 68.437,512 68.437,465.475 "
    />
    <polygon
      style="fill:#E4E4E4;"
      points="443.559,234.931 406.048,214.309 368.546,234.931 331.038,214.309 293.531,234.931 
	256.019,214.309 218.506,234.931 180.991,214.309 143.478,234.931 105.96,214.309 68.441,234.931 68.441,144.16 443.559,144.16 "
    />
    <polygon
      style="fill:#F8F8F8;"
      points="443.559,205.025 406.048,184.403 368.546,205.025 331.038,184.403 293.531,205.025 
	256.019,184.403 218.506,205.025 180.991,184.403 143.478,205.025 105.96,184.403 68.441,205.025 68.441,114.253 443.559,114.253 
	"
    />
    <polygon
      style="fill:#E4E4E4;"
      points="68.437,158.757 68.437,137.223 443.564,137.223 443.564,164.874 68.437,164.874 
	68.437,161.375 "
    />
    <polyline
      style="fill:#FED159;"
      points="460.107,73.029 460.107,144.355 51.893,144.355 51.893,0 460.107,0 460.107,36.298 "
    />
    <g>
      <circle style="fill:#65B4BB;" cx="334.291" cy="72.184" r="24.165" />
      <circle style="fill:#65B4BB;" cx="177.709" cy="72.184" r="24.165" />
      <polygon
        style="fill:#65B4BB;"
        points="312.436,316.111 312.436,292.185 267.963,292.185 267.963,261.365 244.037,261.365 
		244.037,292.185 199.564,292.185 199.564,370.048 288.51,370.048 288.51,400.058 199.564,400.058 199.564,423.984 244.037,423.984 
		244.037,454.804 267.963,454.804 267.963,423.984 312.436,423.984 312.436,346.122 223.49,346.122 223.49,316.111 	"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
